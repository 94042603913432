import { omit } from 'ramda';

export const getSafeValue = (object, exceptions = ['__typename']) => {
  const filteredKeys = Object.keys(object).filter(
    key => !exceptions.includes(key)
  );
  if (
    !filteredKeys.some(
      key =>
        object[key] !== null && object[key] !== '' && object[key] !== undefined
    )
  ) {
    return null;
  }

  return object;
};

export const getSafeArrayValue = (list, field = '__typename') => {
  if (!list) return;
  return list.map(item => omit([field], item));
};
