import { FromTo } from 'types/DateRange';

export const getDate = (epochDate: number | string): string => {
  const date = new Date(epochDate);

  return `${date.toLocaleDateString('en-US', {
    month: 'numeric',
    year: 'numeric',
    day: 'numeric',
  })}`;
};

export const getDateMonth = (epochDate: number | string): string => {
  const date = new Date(epochDate);

  return `${date.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'numeric',
  })}`;
};

export const getFullDate = (epochDate: number): string => {
  const date = new Date(epochDate);
  const locale = Intl.DateTimeFormat().resolvedOptions().locale;
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const formattedDate = getDate(epochDate);
  const formattedTime = date
    .toLocaleDateString(locale, {
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
    })
    .slice(4)
    .replace(/\s/g, '')
    .toLowerCase();
  const formattedTimeZoneName = date
    .toLocaleDateString(locale, {
      day: '2-digit',
      timeZoneName: 'short',
      timeZone,
    })
    .slice(4);

  return `${formattedDate} at ${formattedTime} ${formattedTimeZoneName}`;
};

export const formatDate = (date, showTime = false, showDate = true) => {
  if (!showTime && !showDate) {
    throw new Error('At least one of showTime or showDate must be true');
  }

  const newDate = new Date(date);

  const utcYear = newDate.getUTCFullYear();
  const utcMonth = newDate.getUTCMonth();
  const utcDay = newDate.getUTCDate();
  const utcHours = newDate.getUTCHours();
  const utcMinutes = newDate.getUTCMinutes();
  const utcSeconds = newDate.getUTCSeconds();
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  let formattedResult = '';

  if (showDate) {
    formattedResult = `${monthNames[utcMonth]} ${utcDay}, ${utcYear}`;
    formattedResult += showTime ? ' ' : '';
  }

  if (showTime) {
    formattedResult += `${String(utcHours).padStart(2, '0')}:${String(
      utcMinutes
    ).padStart(2, '0')}:${String(utcSeconds).padStart(2, '0')}`;
  }

  return formattedResult;
};

export const getDateByTwoNumbers = (epochDate: number | string): string => {
  const date = new Date(epochDate);

  const month = date.getMonth() + 1;
  const day = date.getDate().toString();

  return `${month < 10 ? '0' + month : month}/${
    day.length < 2 ? '0' + day : day
  }/${date.getFullYear().toString()}`;
};

export const getEpochDate = (
  date: FromTo | string | number,
  hour = 0,
  second = 0,
  millisecond = 0
): number => {
  if (!date) return;
  if (typeof date === 'number') {
    return date;
  } else if (typeof date === 'string') {
    const [year, month, day] = date.split('-');
    return new Date(
      Number(year),
      Number(month) - 1,
      Number(day),
      hour,
      second,
      millisecond
    ).getTime();
  } else if (typeof date === 'object') {
    return new Date(
      date.year,
      date.month - 1,
      date.day,
      hour,
      second,
      millisecond
    ).getTime();
  }
};

export const getShortISODate = (epochTime?: number) =>
  epochTime ? new Date(epochTime).toISOString().slice(0, 10) : epochTime;

export const getFullISODate = (epochTime?: number | string) =>
  epochTime ? new Date(epochTime).toISOString() : epochTime;

export const getShortISOFromDate = (dateString: string) =>
  dateString?.slice(0, 10);

export const getCurrentShortISODate = () =>
  new Date().toISOString().slice(0, 10);
