import { equals, isNil } from 'ramda';

import { Order } from 'components/QuickView/orders/interfaces';

import { AsnStatus } from 'utils/enums';
import { IRelationshipTypesEnum } from 'utils/enums/relationship';
import { getShortISOFromDate } from 'utils/functions/getDates';
import { getSafeArrayValue, getSafeValue } from 'utils/functions/getSafeValue';

import { IUpdateProductInput, ProductNode } from 'types/catalog';
import { AsnExtendedItem, AsnFormValues } from 'types/supplyTable';

import { MESSAGES } from './messages';

export const LoginFormInitialValues = { email: '', password: '' };

export const RegisterFormInitialValues = {
  email: '',
  firstName: '',
  lastName: '',
  password: '-',
};

export const AddMemberFormInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  companyId: '',
  relationshipType: IRelationshipTypesEnum.Employee,
};

type initialAsnCreationProps = {
  currentLob: string;
};

export const getOrderCreationInitialValues: () => Order = () => {
  return {
    notes: [],
    orderItems: [],
    shipToSameAsBillTo: true,
  };
};

export const getShipmentDetailNode = (
  order: Order,
  key: 'billTo' | 'shipTo'
) => {
  const addressKey = key === 'shipTo' ? 'billingAddress' : 'shippingAddress';
  return {
    name: order?.[`${key}Name`],
    company: order?.[`${key}Company`],
    email: order?.[`${key}Email`],
    phone: order?.[`${key}Phone`],
    address1: order?.[addressKey]?.address1,
    address2: order?.[addressKey]?.address2,
    country: order?.[addressKey]?.country,
    state: order?.[addressKey]?.state,
    city: order?.[addressKey]?.city,
    postalCode: order?.[addressKey]?.postalCode,
  };
};

export const isShipToSameAsBillTo = (order: Order) => {
  return equals(
    getShipmentDetailNode(order, 'billTo'),
    getShipmentDetailNode(order, 'shipTo')
  );
};

export const getOrderEditionInitialValues: (order: Order) => Order = order => {
  return {
    ...order,
    shipToSameAsBillTo: isShipToSameAsBillTo(order),
    expectedShipDate: getShortISOFromDate(order?.expectedShipDate),
    note: '',
  };
};

export const getOrderLineItemsInitialValues = lineItems => ({
  line_items: lineItems,
});

export const getAsnCreationInitialValues: (
  initialValues: initialAsnCreationProps
) => AsnFormValues = ({ currentLob = '' }) => ({
  asn: 'draft',
  identifier: '',
  tracking_number: '',
  asn_status: AsnStatus.Incoming,
  po_number: '',
  ship_date: 0,
  expected_arrival_date: 0,
  carrier_name: '',
  carrier_type: '',
  asn_type: '',
  lob: currentLob,
  location: MESSAGES.NotAvailable,
  note: '',
  line_items: [],
  wh_identifier: '',
});

export const getCloseAsnInitialValues = (asn: AsnExtendedItem) => ({
  locationZone: '',
  locationName: '',
  warehouse:
    typeof asn.warehouse === 'object'
      ? asn?.warehouse?.identifier
      : asn?.warehouse,
});

export const productInputInitialValues = (
  currentProduct: ProductNode
): IUpdateProductInput => {
  return {
    brandId: currentProduct?.brand?.id ?? null,
    name: currentProduct?.name ?? null,
    bulletPoints: currentProduct?.bulletPoints ?? [],
    categories: currentProduct?.categories ?? [],
    images: getSafeArrayValue(currentProduct?.images) ?? [],
    colorId: currentProduct?.color?.id ?? null,
    description: currentProduct?.description || '',
    height: currentProduct?.height || undefined,
    length: currentProduct?.length || undefined,
    parentId: currentProduct?.parent?.id ?? null,
    productId: currentProduct?.id ?? null,
    productType: currentProduct?.productType ?? null,
    sizeId: currentProduct?.size?.id || null,
    sku: currentProduct?.sku || null,
    status: currentProduct?.status || null,
    styleId: currentProduct?.style?.id || null,
    upc: currentProduct?.upc || null,
    weight: currentProduct?.weight || null,
    width: currentProduct?.width || null,
    ship: !isNil(currentProduct) ? currentProduct?.ship : true,
    // Customs Informations fields
    customsInput: getSafeValue({
      code: currentProduct?.productCustoms?.code ?? null,
      defaultValue: currentProduct?.productCustoms?.defaultValue ?? null,
      name: currentProduct?.productCustoms?.name ?? null,
      origin: currentProduct?.productCustoms?.origin ?? null,
    }),
    // Inner Pack fields
    innerPack: currentProduct?.innerPack ?? false,
    innerPackInput: getSafeValue({
      gtin: currentProduct?.productInnerPack?.gtin,
      width: currentProduct?.productInnerPack?.width,
      height: currentProduct?.productInnerPack?.height,
      length: currentProduct?.productInnerPack?.length,
      quantity: currentProduct?.productInnerPack?.quantity,
      weight: currentProduct?.productInnerPack?.weight,
      weightFactor: currentProduct?.productInnerPack?.weightFactor,
    }),
    // Master carton fields
    masterCarton: currentProduct?.masterCarton ?? false,
    masterCartonInput: getSafeValue({
      gtin: currentProduct?.productMasterCarton?.gtin,
      width: currentProduct?.productMasterCarton?.width,
      height: currentProduct?.productMasterCarton?.height,
      length: currentProduct?.productMasterCarton?.length,
      quantity: currentProduct?.productMasterCarton?.quantity,
      weight: currentProduct?.productMasterCarton?.weight,
      weightFactor: currentProduct?.productMasterCarton?.weightFactor,
    }),
    // Pallet fields
    pallet: currentProduct?.pallet ?? false,
    palletInput: getSafeValue({
      gtin: currentProduct?.productPallet?.gtin,
      width: currentProduct?.productPallet?.width,
      height: currentProduct?.productPallet?.height,
      length: currentProduct?.productPallet?.length,
      quantity: currentProduct?.productPallet?.quantity,
      weight: currentProduct?.productPallet?.weight,
    }),
    // Purchasing fields
    purchasingInput: getSafeValue({
      bomCost: currentProduct?.productPurchasing?.bomCost ?? null,
      inboundFreightCost:
        currentProduct?.productPurchasing?.inboundFreightCost ?? null,
      incoterms: currentProduct?.productPurchasing?.incoterms ?? '',
      landedCost: currentProduct?.productPurchasing?.landedCost ?? null,
      leadTime: currentProduct?.productPurchasing?.leadTime ?? null,
      moq: currentProduct?.productPurchasing?.moq ?? null,
      packagingCost: currentProduct?.productPurchasing?.packagingCost ?? null,
      portOfExport: currentProduct?.productPurchasing?.portOfExport ?? '',
      productPackaging:
        currentProduct?.productPurchasing?.productPackaging ?? null,
      productSpecs: currentProduct?.productPurchasing?.productSpecs ?? null,
      purchaserId: currentProduct?.productPurchasing?.purchaserId ?? '',
      supplier: currentProduct?.productPurchasing?.supplier ?? '',
      supplierPartNumber:
        currentProduct?.productPurchasing?.supplierPartNumber ?? '',
      supplierSku: currentProduct?.productPurchasing?.supplierSku ?? '',
      unitCost: currentProduct?.productPurchasing?.unitCost ?? null,
    }),
    // Shipping fields
    shippingInput: getSafeValue({
      height: currentProduct?.productShipping?.height ?? undefined,
      length: currentProduct?.productShipping?.length ?? null,
      packageType: currentProduct?.productShipping?.packageType ?? null,
      shipReadyPackaging:
        currentProduct?.productShipping?.shipReadyPackaging ?? false,
      shippingPackage: currentProduct?.productShipping?.shippingPackage ?? null,
      weight: currentProduct?.productShipping?.weight ?? null,
      weightFactor: currentProduct?.productShipping?.weightFactor ?? null,
      width: currentProduct?.productShipping?.width ?? null,
    }),
  };
};

export const registerShopifyDetailsInitialValues = {
  shop_name: '',
  api_key: '',
  api_secret: '',
  access_token: '',
};

export const AsnFormInitialValues = { tasks: [] };

export const manuallyInventoryAdjustmentInitialValues = (
  previousQuantity = 0
) => {
  return {
    newQuantity: previousQuantity,
    reason: '',
  };
};
