export const MESSAGES = Object.freeze({
  DefaultApiError: 'An error has ocurred, please try again.',
  ShipOrderProcessMessage:
    'We’re analyzing your orders—this process \n may take a moment.',
  UploadOrdersProcessMessage:
    'We’re uploading your orders—this process \n may take a moment.',
  ActionError:
    'There was a system error while trying to execute this action. Please try again.',
  NotAvailable: 'N/A',
  OrderChangedWarning:
    'Recent inventory changes have affected the units available for these orders. Please review your selection as the number of orders able to be allocated has changed.',
  CancelASN: 'ASN successfully canceled.',
  AnalyzingProductsProcessMessage:
    'We’re analyzing your items — this process \n may take a moment.',
  CreatingProductsProgressMessage:
    'We’re creating your items—you can close this window and continue working. We’ll email you when your items are ready.',
  UnallocateOrder: 'This will unallocate the selected order.',
  UnallocationOrderSuccess: 'The order was successfully unallocated!',
  UnallocationOrderFail: 'The order failed to unallocate due to a system error. Please try again.',
});
